.logo{
    bottom: 10%;
}


.card {
    width: 190px;
    height: 254px;
    background-image: linear-gradient(163deg, #00ff75 0%, #3700ff 100%);
   
    /* background-color: #1a1a1a; */
    border-radius: 20px;
    transition: all .3s;
               position: absolute;
               left: 29%;
              
  }
  .pop {
    background-image: url(../images/ideogram__2_-removebg-preview.png);
    background-size: 200px 200px;
    background-repeat: no-repeat;
 
    width: 190px;
    height: 254px;
    background-color: #1a1a1a;
    border-radius:10px;
    transition: all .2s;
   }
   
   .pop:hover {
    transform: scale(0.98);
    border-radius: 20px;
    content: "Portfolio Performance Tracking";
   }
   
   .pop1 {
    background-image: url(../images/news.png);
    background-size: 200px 200px;
    background-repeat: no-repeat;
 
    width: 190px;
    height: 254px;
    background-color: #1a1a1a;
    border-radius:10px;
    transition: all .2s;
   }
   
   .pop1:hover {
    transform: scale(0.98);
    border-radius: 20px;
    content: "Portfolio Performance Tracking";
   }

   .pop2 {
    background-image: url(../images/prdiction.png);
    background-size: 200px 200px;
    background-repeat: no-repeat;
 
    width: 190px;
    height: 254px;
    background-color: #1a1a1a;
    border-radius:10px;
    transition: all .2s;
   }
   
   .pop2:hover {
    transform: scale(0.98);
    border-radius: 20px;
    content: "Portfolio Performance Tracking";
   }

   .card:hover {
    box-shadow: 0px 0px 30px 1px rgba(0, 255, 117, 0.30);
   }




  .card1 {
    width: 190px;
    height: 254px;
    background-image: linear-gradient(163deg, #00ff75 0%, #3700ff 100%);
    border-radius: 20px;
    transition: all .3s;
               position: absolute;
               left: 44%;
  }
  .card2 {
    width: 190px;
    height: 254px;
    background-image: linear-gradient(163deg, #00ff75 0%, #3700ff 100%);
    border-radius: 20px;
    transition: all .3s;
               position: absolute;
               left: 59%;
  }

.searchBox {
    display: flex;
    max-width: 60%;
    align-items: center;
    justify-content: space-between;
    gap: 8px;
    background: #2f3640;
    border-radius: 50px;
    position: absolute;
    left: 30%;
    z-index: 1;
    top: -20px;
    bottom: 40%;
  
    
  }
  
  .searchButton {
    color: white;
    position: absolute;
    right: 8px;
    top: 8px;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background: var(--gradient-2, linear-gradient(90deg, #2AF598 0%, #009EFD 100%));
    border: 0;
    display: inline-block;
    transition: all 300ms cubic-bezier(.23, 1, 0.32, 1);
  }
  /*hover effect*/
  /* button:hover {
    color: #fff;
    background-color: #1A1A1A;
    box-shadow: rgba(0, 0, 0, 0.5) 0 10px 20px;
    transform: translateY(-3px);
  }
  /*button pressing effect*/
 /* button:active {
    box-shadow: none;
    transform: translateY(0);
  } */
  
  .searchInput {
    border: none;
    background: none;
    outline: none;
    color: white;
    font-size: 15px;
    padding: 24px 46px 24px 26px;
    font-size: 1.2rem;
    /* padding: 1rem 3rem; */
    border-radius: 1rem;
    outline: none;
    width: 100%;
  }
  
  